import React, { useState } from "react";
import { navigate, graphql } from "gatsby";
import _ from "lodash";

import {
  AppImage,
  AppSlider,
  Layout,
  Modal,
  VideoPlayIcon,
} from "../components";
import formatLinkHandle from "../utils/formatLinkHandle";

const Home = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const slideData = _.sortBy(
    _.get(data, "allContentfulHomepageSlider.nodes"),
    "order"
  );
  const aboveTheFoldData = _.get(data, "contentfulHomepageAboveTheFold");

  const toggleVideoPlayer = () => {
    setShowModal(true);
  };

  return (
    <Layout
      className="home"
      pageData={_.get(data, "contentfulPageData")}
      isIndexPage
      hideHeader
    >
      <section className="home-container container">
        <div className="page-title">
          <h1>{_.get(aboveTheFoldData, "announcementTitle")}</h1>
          <a
            className="img-container mb-2"
            href={_.get(aboveTheFoldData, "announcementLink.url")}
            target="_blank"
            rel="noopener noreferrer"
            title={_.get(aboveTheFoldData, "announcementLink.title")}
          >
            <AppImage image={_.get(aboveTheFoldData, "announcementImage")} />
          </a>
          <h2 className="h1" align="center">
            {_.get(aboveTheFoldData, "videoTitle")}
          </h2>
          <div
            className="img-container"
            role="button"
            aria-label="Toggle Video"
            tabIndex={0}
            onKeyPress={toggleVideoPlayer}
            onClick={toggleVideoPlayer}
          >
            <AppImage image={_.get(aboveTheFoldData, "videoImage")} />
            <VideoPlayIcon />
          </div>
          {showModal && (
            <Modal
              showModal={showModal}
              handleClick={() => {
                setShowModal(false);
              }}
              className="video-modal"
            >
              <div className="video-container">
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube-nocookie.com/embed/${_.get(
                    aboveTheFoldData,
                    "videoId"
                  )}?autoplay=1&color=68cadb`}
                  title="YouTube video player"
                  allow="autoplay"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            </Modal>
          )}
          {!_.get(aboveTheFoldData, "hideLinks") ? (
            <div className="above-the-fold-row">
              <button
                className="above-the-fold-button"
                onClick={() =>
                  navigate(
                    formatLinkHandle(
                      _.get(aboveTheFoldData, "pressRelease.handle")
                    )
                  )
                }
              >
                {_.get(aboveTheFoldData, "pressRelease.title")}
              </button>
              <a
                className="above-the-fold-button"
                href={_.get(aboveTheFoldData, "download.file.url")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>{_.get(aboveTheFoldData, "download.title")}</button>
              </a>
              <a
                className="above-the-fold-button"
                href={_.get(aboveTheFoldData, "externalVideo.url")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>
                  {_.get(aboveTheFoldData, "externalVideo.title")}
                </button>
              </a>
            </div>
          ) : null}
        </div>
        <div className="slider-container">
          <AppSlider
            className="homepage-slider"
            adaptiveHeight
            autoplay
            autoplaySpeed={10000}
            moreMargins
            responsive={[
              {
                breakpoint: 1920,
                settings: {
                  arrows: true,
                },
              },
            ]}
          >
            {slideData.map((slide, index) => {
              return (
                <div key={index}>
                  <div className="slide-content container flex row">
                    <AppImage className="slide-image" image={slide.image} />
                    <div className="text-container">
                      <p className="service">{slide.heading}</p>
                      <div className="title-container">
                        <h2 className="title">{slide.title}</h2>
                        <div
                          className="description desktop"
                          dangerouslySetInnerHTML={{
                            __html: slide.description.childMarkdownRemark.html,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="description mobile"
                    dangerouslySetInnerHTML={{
                      __html: slide.description.childMarkdownRemark.html,
                    }}
                  />
                </div>
              );
            })}
          </AppSlider>
        </div>
      </section>
    </Layout>
  );
};
export const query = graphql`
  query homeQuery {
    contentfulPageData(title: { eq: "Home" }) {
      title
      description
      image {
        file {
          url
        }
      }
      header
    }
    contentfulHomepageAboveTheFold {
      announcementTitle
      announcementImage {
        title
        fluid(maxWidth: 720, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      announcementLink {
        title
        url
      }
      videoTitle
      videoImage {
        title
        fluid(maxWidth: 720, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      videoId
      hideLinks
      pressRelease {
        title
        handle
      }
      download {
        title
        file {
          url
        }
      }
      externalVideo {
        title
        url
      }
    }
    allContentfulHomepageSlider {
      nodes {
        heading
        order
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(maxHeight: 512, maxWidth: 512, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
export default Home;
